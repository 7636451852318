import Head from 'next/head'
import { CategoryHreflangsFragment } from './CategoryHreflangs.gql'
import { storeCodes, baseUrl } from './utils'

export const CategoryHreflangs = (props: { hreflangs: CategoryHreflangsFragment['hreflang'] }) => {
  const { hreflangs } = props

  // only show hrefLangs for stores defined in LOCALE_STORES
  const relevantHreflangs = hreflangs?.filter((item) =>
    storeCodes.includes(item?.store_code as string),
  )
  const defaultHrefLang = relevantHreflangs?.find((item) => item?.store_code?.includes('_en'))
  return (
    <Head>
      {defaultHrefLang && (
        <link
          rel='alternate'
          href={`${baseUrl}/${defaultHrefLang.relative_url}`}
          hrefLang='x-default'
        />
      )}
      {relevantHreflangs?.map((item) => {
        if (!item) return null
        const language = item?.store_code?.split('_')[1]
        const urlSuffix = `/${language}`

        return (
          <link
            rel='alternate'
            href={`${baseUrl}${urlSuffix}/${item.relative_url}`}
            hrefLang={language}
            key={language}
          />
        )
      })}
    </Head>
  )
}
