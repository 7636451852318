import { Image } from '@graphcommerce/image'
import { Box } from '@mui/material'
import { CategoryThumbnailFragment } from './CategoryThumbnail.gql'

export type CategoryThumbnailProps = CategoryThumbnailFragment & { categoryUid: string }
export const fiveColumnLayoutPages = ['MjAwMw==', 'Mjc5', 'OTU4'] // designers, brands and theme pages should use a five-column layout

export function CategoryThumbnail(props: CategoryThumbnailProps) {
  const { name, thumbnail, categoryUid } = props

  return (
    <Box
      sx={() => ({
        width: '100%',
        minWidth: '100%',
        aspectRatio: fiveColumnLayoutPages.includes(categoryUid) ? '1/1' : '1/1.5',
        background: categoryUid === 'MjAwMw==' ? '#fff' : 'none',
      })}
    >
      {thumbnail && (
        <Image
          layout='fill'
          src={thumbnail}
          width={100}
          height={100}
          sizes='250px'
          alt={name || ''}
          sx={{
            objectFit: categoryUid === 'MjAwMw==' ? 'contain' : 'cover', // Brands page uses 'contain'
            display: 'block',
          }}
          quality={84}
        />
      )}
    </Box>
  )
}
