import { useQuery } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { LayoutTitle } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'

export type CategoryTitleProps = {
  category: {
    uid: string
    description?: string | null
    name?: string | null
    display_mode?: string | null
    meta_title?: string | null
    meta_description?: string | null
    level?: number | null
    is_anchor?: number | null
    image?: string | null
    additional_image?: string | null
    thumbnail?: string | null
    seo_long_description?: string | null
    breadcrumbs?: Array<{
      category_uid: string
      category_name?: string | null
      category_url_path?: string | null
    } | null> | null
    children?: Array<{
      uid: string
      name?: string | null
      level?: number | null
      url_path?: string | null
      include_in_menu?: number | null
      image?: string | null
      thumbnail?: string | null
    } | null> | null
  }
  sx?: SxProps<Theme>
}

export function CategoryTitle(props: CategoryTitleProps) {
  const { data: config } = useQuery(StoreConfigDocument)
  const baseUrl = config?.storeConfig?.secure_base_url ?? 'https://backend.vaessen-creative.com'
  const { category, sx = [] } = props
  const unoptimizedImage: boolean = category.additional_image
    ? category.additional_image.includes('.svg')
    : false

  const hasImage = category.additional_image && baseUrl

  return (
    <Box
      sx={[
        (theme) => ({
          textAlign: 'center',
          padding: `0 ${theme.page.horizontal}`,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={(theme) => ({
          display: 'inline-grid',
          placeItems: 'center',
          gridColumns: '1fr 1fr',
          gridTemplateAreas: '"icon title"',
          margin: '0 auto',
          gridGap: hasImage && theme.spacings.xs,
        })}
      >
        <LayoutTitle
          component='h1'
          variant='h2'
          gutterTop={category.image?.length === 0}
          sx={(theme) => ({
            mt: theme.spacings.lg,
            marginBottom: 0,
          })}
          gutterBottom={false}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {hasImage && (
              <Box
                component='span'
                sx={(theme) => ({
                  mr: theme.spacings.sm,
                  background: theme.palette.primary.dark,
                  borderRadius: '57% 43% / 56% 71% 29% 44%',
                  height: `75px`,
                  width: `75px`,
                  display: 'none',
                  padding: `15px`,
                })}
              >
                <Image
                  loading='eager'
                  style={{ objectFit: 'contain' }}
                  layout='fill'
                  /* eslint-disable-next-line */
                  src={baseUrl + category.additional_image}
                  width={100}
                  height={100}
                  unoptimized={unoptimizedImage}
                />
              </Box>
            )}
            <span>{category?.name}</span>
          </Box>
        </LayoutTitle>
      </Box>
    </Box>
  )
}
