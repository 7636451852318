import { Image } from '@graphcommerce/image'
import { responsiveVal } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'
import { CategoryBannerFragment } from './CategoryBanner.gql'

export type CategoryBannerProps = {
  sx?: SxProps<Theme>
} & CategoryBannerFragment

export function CategoryBanner(props: CategoryBannerProps) {
  const { image, sx = [] } = props

  if (!image) return null

  return (
    <Box
      sx={[
        {
          width: '100%',
          height: responsiveVal(200, 500),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Image
        src={image}
        width={100}
        height={100}
        sizes='100vw'
        sx={{
          width: '100%',
          height: '100% !important',
          objectFit: 'cover',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        quality={84}
      />
    </Box>
  )
}
