import router from 'next/router'
import { useEffect } from 'react'

export function useRestoreScrollPosition() {
  useEffect(() => {
    // used for restoring scroll position on navigation to/from pdp
    const handleRouteChange = (url) => {
      localStorage.setItem('clickedPdpItem', url as string)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    setTimeout(() => {
      // actually restoring a previous navigation
      const resultNodes = document.querySelectorAll('.MuiButtonBase-root.ProductListItem-root')
      if (resultNodes.length > 0 && localStorage.getItem('clickedPdpItem')) {
        const previouslyClickedResultHref = localStorage.getItem('clickedPdpItem')
        const scrollToNode = Array.from(resultNodes).find(
          (node) => node.getAttribute('href') === previouslyClickedResultHref,
        )
        scrollToNode?.scrollIntoView({ behavior: 'smooth' })
        localStorage.removeItem('clickedPdpItem')
      }
    })

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])
}
