import { ProductListParams, productListLink } from '@graphcommerce/magento-product'
import { ProductListPaginationFragment } from '@graphcommerce/magento-product/components/ProductListPagination/ProductListPagination.gql'
import { PaginationProps } from '@mui/material'
import { useState, useEffect } from 'react'
import { PaginationExtended } from './PaginationExtended'

export type ProductPaginationProps = ProductListPaginationFragment &
  Omit<PaginationProps, 'count' | 'defaultPage' | 'page' | 'renderItem'> & {
    params: ProductListParams
    siblingCount?: number
    boundaryCount?: number
  }

export function ProductListPagination({
  page_info,
  params,
  ...paginationProps
}: ProductPaginationProps) {
  const [paginationSiblingCount, setPaginationSiblingCount] = useState<number>(0)
  const [paginationBoundaryCount, setPaginationBoundaryCount] = useState<number>(1)

  function configurePagination() {
    const ww = window.innerWidth

    if (ww < 500) {
      setPaginationSiblingCount(0)
      setPaginationBoundaryCount(1)
    }
    if (ww >= 500 && ww < 960) {
      setPaginationSiblingCount(1)
      setPaginationBoundaryCount(1)
    }
    if (ww >= 960) {
      setPaginationSiblingCount(1)
      setPaginationBoundaryCount(3)
    }
  }

  useEffect(() => {
    configurePagination()
    window.addEventListener('resize', configurePagination, { passive: true })

    return () => window.removeEventListener('resize', configurePagination)
  }, [])

  if (!page_info || !page_info.total_pages || !page_info.current_page) return null

  return (
    <PaginationExtended
      count={page_info?.total_pages}
      page={page_info?.current_page ?? 1}
      siblingCount={paginationSiblingCount}
      boundaryCount={paginationBoundaryCount}
      paginationHref={({ page }) =>
        `${productListLink({ ...params, currentPage: page })}${page === 1 ? '' : '#products'}`
      }
      {...paginationProps}
    />
  )
}
